<template>
<page-view custom-class="page--whiteBg page-home">
  
  <div class="pure-top"  :style="{backgroundImage: 'url('+require('@/assets/images/home/pure-top-bg.png')+')'}">
    <div class="pure-top--container">
      <nav-bar :style="{margin: '16px 0', marginBottom: '10px'}"/>
      <swiper ref="homeSwiper" :options="swiperOptions" class="home-swiper-container" @slideChange="onSwiperChange">
        <swiper-slide class="my-swiper-item" v-for="(item, idx) in yhbhList" :key="idx" @click.native.stop="swiperItemClick(item)">
          <div class="home-swiper__item-container" :style="{backgroundImage: 'url('+swiperCardBgImg+')'}">
            <p>用电户号：{{item.yhbh}}</p>
            <p>用户名称：{{item.yhmc}}</p>
            <p>
              预存余额：
              <span class="price">{{item.dqjy | currencyDollars}}.<small>{{item.dqjy | currencyCents}}</small></span>
              <van-tag v-if="isShowFunc('yfkcr') && item.dqqf == 0" type="success" class="ml-10">预存</van-tag>
            </p>
            <p>
              欠 电 费 ： {{item.dqqf | currency}}
              <van-tag v-if="isShowFunc('dfcj') && item.dqqf > 0" type="warning" class="ml-10">缴费</van-tag>
            </p>
            <!-- <p> 违 约 金 ： {{item.wyj | currency}}</p> -->
            <!-- <div class="home-swiper-pagination" slot="pagination"></div> -->
          </div>
        </swiper-slide>
        <swiper-slide class="my-swiper-item">
          <div class="home-swiper__item-container" :style="{padding: 0}">
              <van-button
                :loading="loadingStatus"
                loading-text="加载中..."
                block
                icon="plus"
                class="bind-yhbh-btn"
                to="/bind-yhbh"
              >绑定户号</van-button>
          </div>
        </swiper-slide>
        <div class="home-swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>

  <div class="container">
    <van-grid :gutter="0" :border="false" 
      :icon-size="jgbm == '1R6' ? 56 : 48" 
      :column-num="jgbm == '1R6' ? 4 : 5">
      <van-grid-item v-if="isShowFunc('dfzd')" :icon="dfcjIcon" text="电费账单" to="/dfzd"/>
      <van-grid-item v-if="isShowFunc('dzfp')" :icon="dzfpIcon" text="电子发票" to="/dzfp"/>
      <van-grid-item v-if="isShowFunc('jfjl')" :icon="dzfpIcon" text="缴费记录" to="/jfjl"/>
      <van-grid-item v-if="isShowFunc('ywbl')" :icon="ywblIcon" text="业务办理" to="/ywbl"/>
      <van-grid-item v-if="isShowFunc('gzbx')" :icon="gzbxIcon" text="诉求管理" to="/gzbx"/>
      <van-grid-item v-if="isShowFunc('schjy')" :icon="gzbxIcon" text="市场化交易" to="/schjy"/>
      <van-grid-item v-if="isShowFunc('ksjf')" :icon="kjjfIcon" text="快捷缴费" to="/ksjf"/>
      <van-grid-item v-if="isShowFunc('bankPayment')" :icon="kjjfIcon" text="快捷缴费" @click="openExtraLink(bankPaymentLink)"/>
      <van-grid-item v-if="isShowFunc('gdcx')" :icon="gdcxIcon" text="进度查询" to="/gdcx"/>
      <van-grid-item v-if="isShowFunc('dzhtgl')" :icon="dzhtglIcon" text="电子合同" to="/dzhtgl"/>
      <van-grid-item :icon="dzhtglIcon" text="光伏并网" to="/gfbw"/>
    </van-grid>
  </div>
<!--  <div class="driver-box"></div>-->

<!--  <a class="read-doc" href="http://yx.bspsd.cn/document/bspsd_wxgzh_helper.pdf">-->
<!--    <div class="read-doc&#45;&#45;icon">-->
<!--      <img :src="readDocIcon" alt="">-->
<!--    </div>-->
<!--    <div class="read-doc&#45;&#45;content">《微信公众号操作手册》</div>-->
<!--  </a>-->

  <div class="driver-box"></div>
  <van-cell :border="false">
    <template #icon>
      <van-icon :name="notifyIcon" size="24" :style="{marginRight: '5px'}"/>
    </template>
    <template #title>
      <span :style="{fontSize: '15px', fontWeight: 'bold'}">通知</span>
    </template>
  </van-cell>

  <div class="container">
    <notify/>
  </div>

  <van-cell :border="false">
    <template #icon>
      <van-icon :name="newsIcon" size="24" :style="{marginRight: '5px'}"/>
    </template>
    <template #title>
      <span :style="{fontSize: '15px', fontWeight: 'bold'}">最新资讯</span>
    </template>
  </van-cell>
  <div class="container" style="margin-bottom: 36px;max-height: 160px;overflow-y: auto;">
    <news-list />
  </div>
  <div class="app-download">
    <a href="http://vx.bsdlgs.cn/bsdlapp.html">保山电力app下载</a>
  </div>
</page-view>
</template>

<script>
import ywblIcon from '@/assets/icons/ywbl.png'
import dfcjIcon from '@/assets/icons/dfcj.png'
import gzbxIcon from '@/assets/icons/gzbx.png'
import dzfpIcon from '@/assets/icons/dzfp.png'
import kjjfIcon from '@/assets/icons/kjjf.png'
import gdcxIcon from '@/assets/icons/gdcx.png'
import dzhtglIcon from '@/assets/icons/dzhtgl.png'
import newsIcon from '@/assets/icons/news.png'
import notifyIcon from '@/assets/icons/notify.png'
import readDocIcon from '@/assets/icons/read-doc.png'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { Tag } from 'vant';


import { mapGetters } from 'vuex';
import { getWxOauthUrl } from '@/utils';
import { getOpenId, setOpenId } from '@/utils/auth';
import {getWxOpenId } from '../../api/login'
import NewsList from './components/news-list.vue'
import Notify from './components/notify.vue'
import NavBar from './components/nav-bar.vue'
import {hlwYxyh_updateYxyhMrxzbzFromYhsj} from "@/api/psdmsqxgl/qxglschema/HlwYxyhAPI";

export default {
  name: 'Home',
  components: {
    [Tag.name]: Tag,
    Swiper,
    SwiperSlide,
    NewsList,
    Notify,
    NavBar
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      ywblIcon,
      dfcjIcon,
      gzbxIcon,
      dzfpIcon,
      kjjfIcon,
      gdcxIcon,
      dzhtglIcon,
      newsIcon,
      notifyIcon,
      readDocIcon,
      swiperCardBgImg: require('@/assets/images/home/top-card-bg.png'),

      swiperOptions:{
        notNextTick: true,
        pagination: {
          el: '.home-swiper-pagination'
        },
        observer:true,  // 启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。
        observeParents: true,  // 将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新。
      },

      bankPaymentLink: "https://cpcdpay.spdb.com.cn/ybillh/#/pages/payBill/payitem?mchtCode=780085212002010",
      loadingStatus:false, //查询绑定营销用户加载状态
    }
  },
  computed: {
    ...mapGetters(['jgbm','token','yhbhList','bindYxyh','yhsj']),
    isLogedin() {
      return this.token;
    },
    funcList() {
      let list = [];
      switch(this.jgbm) {
        case "1R6":
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dfcj','yfkcr'];
          break;
        case "11":
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dzhtgl', 'dfcj','yfkcr'];
          break;
        default:
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'bankPayment', 'gdcx', 'dzhtgl'];
          break;
      }
      return list;
    }
  },
  methods: {
    swiperItemClick(item) {
      if (item.dqqf > 0) {
        this.isShowFunc('dfcj') && this.$router.push('/dfcj/'+item.yhbh);
      } else {
        this.isShowFunc('yfkcr') && this.$router.push('/yfkcr/'+item.yhbh);
      }
    },
    isShowFunc(funcName) {
      return this.funcList.indexOf(funcName) != -1;
    },
    openExtraLink(url) {
      if(this.bindYxyh.yhbh){
        url = url+'&userId='+this.bindYxyh.yhbh;
      }
      window.open(url, "_blank");
    },
    onSwiperChange(){
      let index = this.$refs.homeSwiper.$swiper.activeIndex;
      if(this.yhbhList.length>=index){
        this.$store.commit('SET_BIND_YXYH',this.yhbhList[index]);
        //同步设置默认绑定户号
        hlwYxyh_updateYxyhMrxzbzFromYhsj(this.yhbhList[index]['yhbh'], this.yhsj);
      }
    }
  },
  async mounted() {
    if (this.isLogedin) {
      this.loadingStatus = true;
      await this.$store.dispatch('getYhbhList');
      this.loadingStatus = false;
    }
  },
  created() {
    // const code = this.$route.query.code || ''
    // if (!this.isLogedin) {
    //   if (code) {
    //     // 通过 code 获取 openid 
    //     let loadingInstance = this.$toast.loading({
    //       duration: 0, // 持续展示 toast
    //       forbidClick: true,
    //       message: '登录中',
    //     });

    //     getWxOpenId(code).then(res => {
    //       console.log("res********************"+res);
    //       if (res.status == 200) {
    //         const openId = res.data;
    //         setOpenId(openId);
            
    //         this.$store.dispatch('loginWithOpenId').then(res => {
    //           this.$store.dispatch('getUserInfo');
    //           this.$store.dispatch('getYhbhList');
    //           loadingInstance.clear();
    //           this.$toast.success('登录成功！');
    //         }).catch(err => {
    //           console.log(err);
    //           loadingInstance.clear();
    //           this.$toast.fail('登录失败5');
    //         });
    //       } else {
    //         loadingInstance.clear();
    //         this.$toast.fail('登录失败6');
    //       }
    //     }).catch(err => {
    //       console.log(err);
    //       loadingInstance.clear();
    //       this.$toast.fail('登录失败7');
    //     });
    //   }
    // }
  },
}
</script>


<style lang="scss" scoped>
  @import 'src/styles/variable.scss';

  .page-home /deep/ {
    .container {
      margin: 0 16px;
    }
    .pure-top {
      width: 100%;
      height: 226px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      background-size: 100%;
      background-position: center top;
      background-repeat: no-repeat;
    }
    .pure-top--container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    
    .home-swiper-container {
      // margin: 15px;
    }
    .my-swiper-item {
      display: block;
      padding: 10px 20px;
      touch-action: none;
      box-sizing: border-box;
    }
    .home-swiper__item-container {
      padding: 16px 20px;
      height: 140px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 1px 2px 10px -5px rgba(208, 88, 44, 0.3);
      background-size: 130px;
      background-position: right 10px;
      background-position: calc(100% - 10px) 20px;
      background-repeat: no-repeat;
      p {
        line-height: 1;
        font-size: 15px;
        margin-bottom: 10px;
        color: rgba(85, 85, 85, 0.78);
        font-family: 微软雅黑;
        font-weight: normal;
      }
      .price {
        color: #328e08;
        text-shadow: 0px 1px #fff;
      }
    }

    .home-swiper-pagination {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      z-index: 1;
      text-align: center;
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: $color-primary;
      opacity: 0.3;
      margin-right: 6px;
    }
    .swiper-pagination-bullet-active {
      background-color: $color-primary;
      opacity: 1;
    }

    .bind-yhbh-btn {
      height: 100%;
      border: none;
      .van-button__content {
        flex-direction: column;
      }
      .van-button__icon {
        font-size: 28px;
      }
      .van-button__text {
        font-size: 14px;
        margin: 5px 0;
      }
    }

    .van-grid {
      margin-bottom: 10px;
    }
    .van-grid-item__text {
      font-size: 12px;
      margin-top: 4px;
    }
    .van-grid-item__content { 
      padding: 8px 0;

    }


    .bind-yhbh, .yhzd-query{
      .van-grid-item__content {
        background-color: orange;
        color: #fff;
        border-radius: 6px;
      }
      .van-grid-item__text {
        color: #fff;
        font-size: 14px;
      }
    }
    .yhzd-query .van-grid-item__content {
        background-color: #727cd5;
    }

    .read-doc {
      display: inline-block;
      width: 100%;
      height: 36px;
      padding: 20px 15px;
      text-decoration: none;
      color: #3a3a3a;

      &:hover, &:visited {
        color: #3a3a3a;
      }
    }

    .read-doc--icon {
      float: left;
      margin-right: 10px;
      img {
        width: 36px;
        height: 36px;
      }
    }

    .read-doc--content {
      line-height: 36px;
    }
    .app-download{
      /*position: fixed;*/
      width: 100%;
      bottom:0px;
      text-align: center;
      background-color: white;
      padding: 15px 0;
      font-size: 13px;
      color: #007aff;
      font-weight: bolder;

      &:hover, &:visited {
        color: #3a3a3a;
      }
    }
    .driver-box{
      height: 6px;
    }
  }
</style>